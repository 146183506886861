import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import React from 'react';
import classNames from 'classnames';
import './blog.css';

import Layout from '../components/layout';
import Newsletter from '../components/Newsletter';

const Author = ({author}) => (
  <div className="inline-flex items-center space-x-2 ml-4">
    <img
      alt={author.frontmatter.name}
      className="w-8 h-8 rounded-full"
      src={author.frontmatter.image}
    />
    <a className="text-primary" href={author.frontmatter.url}>
      {author.frontmatter.name}
    </a>
  </div>
);

export default props => {
  const {
    data: {site, markdownRemark},
  } = props;
  const {siteMetadata} = site;
  const {excerpt, frontmatter, html, timeToRead} = markdownRemark;

  const title = `${frontmatter.title} - Fleet Serverless function fastest`;

  return (
    <Layout theme={frontmatter.theme}>
      <Helmet>
        <title>{title}</title>
        <meta
          content={`https://fleetfn.com${frontmatter.imageUrl}`}
          name="og:image"
        />
        <meta
          content={`https://fleetfn.com${frontmatter.imageUrl}`}
          name="twitter:image"
        />
        <meta content="article" name="og:type" />
        <meta content="Reading time" name="twitter.label1" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={`${timeToRead} min read`} name="twitter:data1" />
        <meta content={excerpt} name="description" />
        <meta content={excerpt} name="og:description" />
        <meta content={excerpt} name="twitter:description" />
        <meta content={siteMetadata.author} name="twitter:creator" />
        <meta content={title} name="og:title" />
      </Helmet>
      <div className="relative mt-12 px-6 lg:px-12">
        <div className="container flex flex-col">
          <div className="blog flex flex-wrap w-full mb-40">
            {frontmatter.theme === 'dark' && (
              <img
                alt={frontmatter.title}
                class="blog-img mb-12 rounded"
                src={frontmatter.imageUrl}
              />
            )}
            <div className="flex w-full">
              <div className="block mb-4">
                <p className="py-3 text-4xl leading-tight font-semibold">
                  {frontmatter.title}
                </p>
                <p className="flex items-center text-gray font-regular">
                  {`${markdownRemark.fields.date} by `}
                  {toCommaSeparatedList(frontmatter.author, author => (
                    <Author key={author.frontmatter.name} author={author} />
                  ))}
                </p>
              </div>
            </div>
            <div className="flex-1 mt-12">
              <div
                className={classNames('blog-content', {
                  dark: frontmatter.theme === 'dark',
                })}
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </div>
          </div>
          <Newsletter theme={frontmatter.theme} />
        </div>
      </div>
    </Layout>
  );
};

const addString = (list, string) =>
  list.push(<span key={`${list.length}-${string}`}>{string}</span>);

const toCommaSeparatedList = (array, renderCallback) => {
  if (array.length <= 1) {
    return array.map(renderCallback);
  }

  const list = [];

  array.forEach((item, index) => {
    if (index === array.length - 1) {
      addString(list, array.length === 2 ? ' and ' : ', and ');
      list.push(renderCallback(item, index));
    } else if (index > 0) {
      addString(list, ', ');
      list.push(renderCallback(item, index));
    } else {
      list.push(renderCallback(item, index));
    }
  });

  return list;
};

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        author
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt(pruneLength: 500)
      timeToRead
      html
      frontmatter {
        title
        imageUrl
        author {
          frontmatter {
            name
            image
            url
          }
        }
        theme
      }
      fields {
        date(formatString: "MMMM DD, YYYY")
        path
        slug
      }
    }
  }
`;
